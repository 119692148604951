/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
.mat-mdc-table .mdc-data-table__row {
    height: 35px !important;
}

.table-container {
    height: calc(100% - 50px); 
    overflow: auto;
    padding-bottom: 70px; 
}

.mat-mdc-table .mdc-data-table__row {
    height: 35px !important;
}

.link {
    color: #7A8CF8;
}

.ngx-mat-tel-input-container button {
    margin-top: 7px;
}

.readonly {
    cursor: not-allowed;
    pointer-events: none;
    user-select: none;
    opacity: 0.7;
}

.tables-by-id {
    padding-bottom: 65px;
    width: 100%;
}

.paginator-by-id {
    position: relative !important;
    margin-top: 20px;
}

.expansion-header {
    background: #4F46E5;

    &:hover {
        background: #4F46E5 !important;
    }
}

.expansion-panel {
    margin-bottom: 5px !important;
}

.expansion-row-border {
    border-bottom: 0.5px solid grey !important;
}

.mat-expansion-panel-body {
    padding: 0 !important;
}

.leading-6 {
    padding-left: 30px !important;
}

.mat-expansion-panel .mat-expanded {
    background: #4F46E5 !important;
}

.pointer {
    cursor: pointer;
}

.mat-drawer-backdrop.mat-drawer-shown {
    background-color: rgba(0, 0, 0, 0) !important;
}

.sticky-save-button {
    position: sticky;
    bottom: 0;
    background-color: white;
    padding: 20px;
    z-index: 1;
}

.sticky-container {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
}


